import axios from "axios";
import request from "../../request";

export const GETCATALOGPLP = (
    dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA, currentA1,
    currentA2, currentA3, currentVariety, setLoader, autoLoader,
    variety, A1, A2, A3) => {
    let checkedA1;
    let checkedA2;
    let checkedA3;
    if (variety[0]?.children_data?.filter(data => data?.parent?.includes(A1[0]?.parent))) {
        checkedA1 = currentA1
    }
    if (A1[0]?.children_data?.filter(data => data?.parent?.includes(A2[0]?.parent))) {
        checkedA2 = currentA2
    }
    if (A2[0]?.children_data?.filter(data => data?.parent?.includes(A3[0]?.parent))) {
        checkedA3 = currentA3
    }
    const getcatalogplp = async () => {
        setLoader(true)
        try {
            const data = await axios({
                method: "post",
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
                url: `${request.catalogfilter}/${COMPANYID}/${STOREID}/${USERID}?category_id=${ID}&type_id=${SUBID}&country_id=${COUNTRYDATA
                    }&price_from=&price_to=&discount_from=&discount_to=&rating=&current_page=${autoLoader}&variety=${currentVariety}&assortment_1=${checkedA1 ? checkedA1 : ""}&assortment_2=${checkedA2 ? checkedA2 : ""}&assortment_3=${checkedA3 ? checkedA3 : ""}&brand_type=${BRANDDATA}`,
            });
            dispatch({
                type: "CATALOGPLP__DATA",
                payload: data?.data[0]
            })
            setLoader(false)
        } catch (e) {
            setLoader(false)
            console.log(e)
        }
    };
    getcatalogplp()
}

export const GETMOBILECATALOGPLP = (dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA, VARIETY, A1, A2, A3, setLoader, autoLoader) => {
    const getcatalogplp = async () => {
        setLoader(true)
        try {
            const data = await axios({
                method: "post",
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
                url: `${request.catalogfilter}/${COMPANYID}/${STOREID}/${USERID}?category_id=${ID}&type_id=${SUBID
                    }&country_id=${COUNTRYDATA}&price_from=&price_to=&discount_from=&discount_to=&rating=&current_page=${autoLoader}&variety=${VARIETY}&assortment_1=${A1
                    }&assortment_2=${A2}&assortment_3=${A3}&brand_type=${BRANDDATA}&mobile=1`,
            });
            dispatch({
                type: "CATALOGPLP__DATA",
                payload: data?.data[0]
            })
            setLoader(false)
        } catch (e) {
            setLoader(false)
            console.log(e)
        }
    };
    getcatalogplp()
}

export const GETPRODUCTPLP = (dispatch, TOKEN, COMPANYID, USERID, STOREID, ID, SubID, currentVariety, currentA1, currentA2, currentA3, setLoader, autoLoader,
    variety, A1, A2, A3, daysSelect, searchQuery, typeSelect) => {
    // setTimeout(() => { }, 1000)
    async function getproductplp() {
        setLoader(true);
        let purchasedDate = "";
        if (daysSelect != null) {
            purchasedDate = daysSelect
        }
        let checkedA1;
        let checkedA2;
        let checkedA3;
        if (variety[0]?.children_data?.filter(data => data?.parent?.includes(A1[0]?.parent))) {
            checkedA1 = currentA1
        }
        if (A1[0]?.children_data?.filter(data => data?.parent?.includes(A2[0]?.parent))) {
            checkedA2 = currentA2
        }
        if (A2[0]?.children_data?.filter(data => data?.parent?.includes(A3[0]?.parent))) {
            checkedA3 = currentA3
        }

        try {
            const data = await axios({
                method: "post",
                url: `${request.productplpfilter}/${COMPANYID}/${STOREID}/${USERID
                    }?category_id=${ID}&type_id=${SubID}&country_id=&price_from=&price_to=&discount_from=&discount_to=&rating=&current_page=${autoLoader}&variety=${currentVariety
                    }&assortment_1=${checkedA1 ? checkedA1 : ""}&assortment_2=${checkedA2 ? checkedA2 : ""}&assortment_3=${checkedA3 ? checkedA3 : ""}&brand_type=&product_type=&purchase=${typeSelect || ""}&search=${searchQuery}&days=${purchasedDate}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: "PRODUCTPLP__DATA",
                payload: data?.data[0]
            })
        } catch (e) {
            setLoader(false);
            console.log(e);
        } finally {
            setLoader(false);
        }
    };
    getproductplp()
}

export const PRODUCTLEFTFILTER = (dispatch, COMPANYID, USERID, STOREID, daysSelect) => {
    let PURCHASEID;
    if (daysSelect == null) {
        PURCHASEID = 0
    } else {
        PURCHASEID = daysSelect
    }
    let TOKEN = localStorage.getItem('token')
    async function productleftfilter() {
        dispatch({
            type: 'PRODUCTPLP__LEFTFILTER',
            payload: []
        })
        try {
            const data = await axios({
                method: "post",
                url: request.PRODUCTLEFTFILTER,
                data: {
                    company_id: COMPANYID,
                    customer_id: USERID,
                    storeId: STOREID,
                    purchaseId: PURCHASEID.toString()
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'PRODUCTPLP__LEFTFILTER',
                payload: data?.data
            })
        } catch (e) {
            console.log(e)
        }
    }
    productleftfilter()
}
export const PURCHASEPRODUCTLEFTFILTER = (dispatch, COMPANYID, USERID, STOREID, daysSelect, typeSelect) => {
    let PURCHASEID;
    if (daysSelect == null) {
        PURCHASEID = 0
    } else {
        PURCHASEID = daysSelect
    }
    let TOKEN = localStorage.getItem('token')
    async function productleftfilter() {
        dispatch({
            type: 'PRODUCTPLP__LEFTFILTER',
            payload: []
        })
        try {
            const data = await axios({
                method: "post",
                url: request.PURCHASEPRODUCTLEFTFILTER,
                data: {
                    company_id: COMPANYID,
                    customer_id: USERID,
                    storeId: STOREID,
                    purchaseId: typeSelect ? typeSelect : '',
                    days: daysSelect ? daysSelect.toString() : "",
                    page: "1",
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })

            dispatch({
                type: 'PRODUCTPLP__LEFTFILTER',
                payload: data?.data
            })
        } catch (e) {
            console.log(e)
        }
    }
    productleftfilter()
}

export const BRANDFILTER = (dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA) => {
    async function brandfilter() {
        try {
            const data = await axios({
                method: "post",
                url: `${request.brandFilterCategory}${COMPANYID}/${STOREID}/${USERID}?category_id=${ID}&type_id=${SUBID}&variety=&assortment_1=&assortment_2=&assortment_3=&country_id=${COUNTRYDATA}&price_from=&price_to=&discount_from=0&discount_to=0&rating=&current_page=&product_type=`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: "CATALOGBRAND__DATA",
                payload: data?.data
            })
        } catch (e) {
            console.log(e)
            // toast.error(e.response?.data?.message);
        }
    }
    brandfilter();
}

export const COUNTRYFILTER = (dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA) => {
    async function countryfilter() {
        try {
            const data = await axios({
                method: "post",
                url: `${request.catalogcountry}/${COMPANYID}/${STOREID}/${USERID}?category_id=${ID}&type_id=${SUBID}&discount_from=0&discount_to=0&rating=&current_page=0&brand_type=${BRANDDATA}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            const combinedData = data?.data.map(item => ({
                country_id: item.country_id,
                country_name: item.country_name,
                flag: item.flag
            }));
            // console.log(combinedData,"combinedData")
            dispatch({
                type: "CATALOGCOUNTRY__DATA",
                payload: combinedData
            });
        } catch (e) {
            console.log(e);
        }
    }
    countryfilter();
}

export const GENERALFILTER = (dispatch, SUBID) => {
    dispatch({
        type: "GENERAL__FILTER",
        payload: []
    })
    let TOKEN = localStorage.getItem('token')
    async function generalFIlter() {
        try {
            const data = await axios({
                method: "get",
                url: `${request.generalFIlter}/${SUBID ? SUBID : 0}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: "GENERAL__FILTER",
                payload: data?.data[0]
            })
        } catch (e) {
            console.log(e)
        }
    }
    generalFIlter();
}