import axios from "axios";
import request from "../../request";

const campaignCache = {}; // Simple in-memory cache

// Function to get the current timestamp
const getCurrentTimestamp = () => new Date().getTime();

export const CAMPAIGNPRODUCTS = (dispatch, TOKEN, COMPANYID, STOREID, USERID, Id, storeid, categoryId, type_id, currentpage) => {
    async function fetchCampaignProducts() {
        const cacheKey = `${Id}-${STOREID}-${categoryId}-${type_id}-${currentpage}`;

        // Check if data is already cached and if it's still valid (less than 6 hours old)
        if (campaignCache[cacheKey] && (getCurrentTimestamp() - campaignCache[cacheKey].timestamp < 6 * 60 * 60 * 1000)) {
            dispatch({
                type: "CAMPAIGN_PRODUCTS_SUCCESS",
                payload: campaignCache[cacheKey].data // Accessing the cached data
            });
        }

        const url = `${request.CAMPAIGNPRODUCTS}/${Id}/${STOREID}/${categoryId}?&type_id=${type_id}&current_page=${currentpage}`;
        dispatch({ type: 'CAMPAIGN_PRODUCTS_LOADING' });

        try {
            const response = await axios({
                method: "post",
                url: url,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                }
            });

            if (response.data) {
                const responseData = response.data[0].data;
                const { products, details } = responseData;
                const { banner_image: bannerImage, banner_image_mobile: responsiveImage, title: filtertitle, titlethai: thaititle, description: filterdetails, descriptionthai: filterdetailsthai } = details;

                const payload = {
                    products,
                    bannerImage,
                    responsiveImage,
                    filtertitle,
                    filterdetails,
                    thaititle,
                    filterdetailsthai
                };

                // Cache the response with a timestamp
                campaignCache[cacheKey] = {
                    data: payload,
                    timestamp: getCurrentTimestamp() // Store the current timestamp
                };

                dispatch({
                    type: "CAMPAIGN_PRODUCTS_SUCCESS",
                    payload
                });
            } else {
                dispatch({
                    type: 'CAMPAIGN_PRODUCTS_FAILURE',
                    payload: 'No products data found'
                });
            }
        } catch (error) {
            dispatch({
                type: 'CAMPAIGN_PRODUCTS_FAILURE',
                payload: error.message
            });
        }
    }

    fetchCampaignProducts();
}

export const CAMPAIGNPRODUCTSFilterproduct = (dispatch, TOKEN, Id, STOREID, categoryId) => {
    async function fetchCampaignProductsFilter() {
        const url = `${request.CAMPAIGNPRODUCTSFilter}/${Id}/${STOREID}`;

        dispatch({ type: 'CAMPAIGN_PRODUCTS_FILTER_LOADING' });

        try {
            const response = await axios({
                method: "post",
                url: url,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                }
            });
            if (response.data && Array.isArray(response.data)) {
                const responseData = response.data;
                dispatch({
                    type: "CAMPAIGN_PRODUCTS_FILTER_SUCCESS",
                    payload: responseData
                });
            } else {
                dispatch({
                    type: 'CAMPAIGN_PRODUCTS_FILTER_FAILURE',
                    payload: 'No filter data found'
                });
            }
        } catch (error) {
            dispatch({
                type: 'CAMPAIGN_PRODUCTS_FILTER_FAILURE',
                payload: error.message
            });
        }
    }

    fetchCampaignProductsFilter();
}
